import React, { Component } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"


class Chimp extends Component {



  render() {
    const url = "https://mc.us20.list-manage.com/signup-form/subscribe?u=e2022e1588368caec88e05f39&id=009ce7e1f0"
    const CustomForm = ({ status, message, onValidated }) => {
      let email
      const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
        });

      const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          submit()

        }
      }


      return (
        <div className="emailForm">
          {(status !== "success" && status !== "error" && status !== "sending") && (
            <input
              className="email"
              type="email"
              placeholder="Your email"
              onKeyPress={handleKeyPress}
            />
          )}
          {(status !== "success" && status !== "error" && status !== "sending") && (
            <button className="reserve" onClick={submit}>
              Reserve
            </button>
          )}
          {status === "sending" && 
            <div className="emailFormMessage" style={{ color: "blue" }}>
              sending...
            </div>
          }
          {status === "error" && (
            <div className="emailFormMessage"
              style={{ color: "red" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div className="emailFormMessage"
              style={{ color: "#FE9800" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      );
    };

    return(
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
         )}
      />
    )
  }
}

export default Chimp