import React, { Component } from 'react'
import sprite from './assets/sprite.png'
import phone from './assets/iPhoneXS.png'
import './App.css'
import Chimp from './MailChimp'
import Privacy from './Privacy'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"


class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      imageIsReady: false
    }

    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount() {
    const img = new Image()
    const hero = new Image()
    img.src = phone
    hero.src = sprite

    img.onload = () => {
      this.setState({ imageIsReady: true })
    }
  }

  render() {
    const { imageIsReady } = this.state

    if (!imageIsReady) {
      return null
    } else {  
      return (
        <Router>
          <div className="App">
            <header className="App-header"></header>
            <main className="App-main">
              
              <Route path="/" exact render={() => 
                <article className="App-main-article">
                  <div className="App-main-phone">   
                    <img src={phone} className="App-phone" alt="phone" />
                  </div>
                  <div className="App-main-form">
                    <div className="App-main-logo">
                      <h1 className="hero">Origami</h1>
                      <img src={sprite} className="App-logo" alt="logo" />
                    </div>
                    <div className="App-main-text">
                      <span>For the explorer, storyteller</span><br></br>
                      <span>student, teacher, minimalist, collector</span><br></br>
                      <span>mother, daughter, father, son</span><br></br>
                      <span>and lover in</span><br></br>
                      <span>You</span><br></br>
                      <h2>We're preparing your box</h2>
                      <Chimp></Chimp>
                    </div>
                  </div>
                </article>
              }/>          
              <Route path="/privacy" component={Privacy}/>

            </main>
            <footer className="App-footer">
              <div className="App-footer-nav">
                <Route path="/" exact render={() =>
                  <Link to="/privacy">
                    Privacy
                  </Link>
                }/>
                <Route path="/privacy" render={() =>
                  <Link to="/">
                    Home
                  </Link>
                }/>
                <span>
                  © 2019 theCloob
                </span>
              </div>
            </footer>
          </div>
        </Router>
      );
    }
  }
}

export default App;
